import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CompanyLoggerConfigurationList from '@/views/components/logger/CompanyLoggerConfigurationList.vue';
import IdleLoggersList from '@/views/components/logger/IdleLoggersList.vue';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import { CompanyLogger } from '@/entities/models/CompanyLogger';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import { vxm } from '@/store/store.vuex';
import SourceRepository from '@/services/repository/SourceRepository';
var companyLoggerRepository;
var departmentRepository;
var sourceRepository;
var vxDepartmentStore = vxm.departmentStore;
let CompanyLoggerManagement = class CompanyLoggerManagement extends Vue {
    constructor() {
        super(...arguments);
        this.companyLoggerList = [];
        this.sourceList = [];
        this.isLoading = true;
        this.dateTimeManager = null;
        this.departmentSettings = null;
        this.SELECTED_DEPARTMENT_ID = null;
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        departmentRepository = new DepartmentRepository(this);
        sourceRepository = new SourceRepository(this);
        await this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
        this.createApiCalls();
    }
    createApiCalls() {
        let allPromises = [];
        allPromises.push(companyLoggerRepository.getCompanyLoggers());
        allPromises.push(sourceRepository.getAllSources());
        allPromises.push(departmentRepository.getDepartment());
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2]);
        });
    }
    async processLoadedDataForPage(companyLoggerList, sourceList, department) {
        CompanyLogger.addSources(companyLoggerList.getData(), sourceList.getData());
        this.companyLoggerList = companyLoggerList.getData();
        this.sourceList = sourceList.getData();
        this.departmentSettings = department.settings;
        this.isLoading = false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
CompanyLoggerManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.LOGGER)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            if (this.SELECTED_DEPARTMENT_ID !=
                this.getSelectedDepartmentId()) {
                this.loadData();
            }
            next(); // to resolve hook
        },
        components: { CompanyLoggerConfigurationList, IdleLoggersList }
    })
], CompanyLoggerManagement);
export default CompanyLoggerManagement;

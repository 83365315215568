var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("company_loggers.msg.file_download_info")) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("component.logger.list.title")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                { staticStyle: { "font-size": "1rem" } },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled:
                              column.field == _vm.tableColumns.name.field ||
                              column.field ==
                                _vm.tableColumns.serialNumber.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `component.logger.list.table.${column.field}`
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm.isLoggedFromAdmin
                ? [
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          position: "is-bottom-left",
                          "append-to-body": "",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function () {
                                return [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "admin.component.loggers.detail.actions.title"
                                        ),
                                        position: "is-left",
                                        type: "is-dark",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: { "font-size": "1rem" },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              type: "is-primary",
                                              icon: "ellipsis-v",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3412236272
                        ),
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.changeAllVisibility(false)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "eye-slash" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "component.logger.list.actions.hideAll"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.changeAllVisibility(true)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", { attrs: { icon: "eye" } }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "component.logger.list.actions.showAll"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      !_vm.isLoading
        ? _c(
            "b-table",
            {
              ref: "table",
              staticClass: "table-wrap custom-card-space-mobile",
              attrs: {
                data: _vm.sourceList,
                paginated: "",
                "current-page": _vm.page,
                "per-page": _vm.pageSize,
                total: _vm.total,
                "default-sort-direction": _vm.sortDirection,
                "default-sort": _vm.sortField,
                striped: "",
                "page-input": true,
                "pagination-order": "is-centered",
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.page = $event
                },
                "update:current-page": function ($event) {
                  _vm.page = $event
                },
                "page-change": _vm.onPageChange,
                sort: _vm.onSort,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.logger.list.table.empty")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "bottom-left",
                    fn: function () {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.notifications_list.table.row_per_page"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "b-field",
                          { staticClass: "pl-2 mb-4-mobile" },
                          [
                            _c(
                              "b-select",
                              {
                                on: { input: _vm.rowsPerPageSelected },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function ($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize",
                                },
                              },
                              _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                                return _c(
                                  "option",
                                  { key: option, domProps: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm.sourceList && _vm.sourceList.length > 0
                    ? {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "is-flex is-justify-content-flex-end",
                              },
                              [
                                _c("p", { staticClass: "pt-2 has-text-grey" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.company.list.table.results",
                                          {
                                            from: _vm.fromTableData,
                                            to: _vm.toTableData,
                                            total: _vm.total,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.name.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.name.field}`
                  ),
                  visible: _vm.tableColumns.name.visible,
                  sortable: _vm.tableColumns.name.visible,
                  width: "20%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          !_vm.inWizard
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "logger_detail",
                                      params: {
                                        locationId: props.row.id,
                                        departmentId:
                                          _vm.$route.params.departmentId,
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(props.row.name) + " ")]
                              )
                            : _c("p", [_vm._v(_vm._s(props.row.name))]),
                          props.row.logger &&
                          props.row.logger.state === _vm.ServiceState.ERROR
                            ? _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-danger" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("loggers.logger_states.Error")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          props.row.activeService
                            ? _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-info" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("loggers.logger_states.Service")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1753136693
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.serialNumber",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.serialNumber.field}`
                  ),
                  visible: _vm.tableColumns.serialNumber.visible,
                  sortable: _vm.tableColumns.serialNumber.visible,
                  width: "15%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            [
                              props.row.logger && !_vm.inWizard
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "company_logger_detail",
                                          params: {
                                            locationId:
                                              props.row.logger.companyLoggerId,
                                            departmentId:
                                              _vm.$route.params.departmentId,
                                            lang: _vm.$route.params.lang,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row.logger.serialNumber
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              props.row.logger && !props.row.logger.active
                                ? _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "company_loggers.msg.deactivated_comp_logger"
                                        ),
                                        position: "is-right",
                                        type: "is-dark",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "ml-1",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "exclamation-triangle",
                                          type: "is-danger",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3444245319
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.description.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.description.field}`
                  ),
                  visible: _vm.tableColumns.description.visible,
                  sortable: _vm.tableColumns.description.visible,
                  width: "30%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("p", { staticClass: "description" }, [
                            _vm._v(_vm._s(props.row.description)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2514484412
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.lastSeen.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.lastSeen.field}`
                  ),
                  visible: _vm.tableColumns.lastSeen.visible,
                  sortable: _vm.tableColumns.lastSeen.visible,
                  width: "15%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.dateTimeManager.formatTime(
                                new Date(props.row.lastSeen * 1000),
                                null,
                                null,
                                null
                              )
                            )
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4082372116
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.battery",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.battery.field}`
                  ),
                  visible: _vm.tableColumns.battery.visible,
                  sortable: _vm.tableColumns.battery.visible,
                  width: "5%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.logger && props.row.logger.battery != null
                            ? _c(
                                "div",
                                {
                                  staticClass: "is-flex is-align-items-center",
                                },
                                [
                                  props.row.logger.battery <= 15
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-dark",
                                            label: _vm.$t(
                                              "component.dashboard.status.batteryStatus.criticalTooltip"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "is-size-5",
                                            attrs: {
                                              icon: "battery-empty",
                                              type: "is-primary",
                                            },
                                          }),
                                          _c("b-icon", {
                                            staticClass: "is-size-5",
                                            attrs: {
                                              icon: "exclamation-triangle",
                                              type: "is-primary",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : props.row.logger.battery <= 50
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-dark",
                                            label: _vm.$t(
                                              "component.dashboard.status.batteryStatus.lowTooltip"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "is-size-5",
                                            attrs: {
                                              icon: "battery-quarter",
                                              type: "is-info",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-dark",
                                            label: _vm.$t(
                                              "component.dashboard.status.batteryStatus.okTooltip"
                                            ),
                                            position: "is-left",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "is-size-5",
                                            attrs: {
                                              icon: "battery-full",
                                              type: "is-success",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            : _c("p", [_vm._v("-")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3456258473
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: "logger.isOnline",
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.isOnline.field}`
                  ),
                  visible: _vm.tableColumns.isOnline.visible,
                  sortable: _vm.tableColumns.isOnline.visible,
                  "custom-sort": _vm.sortByIsOnline,
                  width: "10%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            { staticClass: "is-flex is-align-items-center" },
                            [
                              _c("b-icon", {
                                staticClass: "is-size-7",
                                attrs: {
                                  type:
                                    props.row.logger &&
                                    props.row.logger.isOnline == true
                                      ? "is-success"
                                      : "is-danger",
                                  icon: "circle",
                                },
                              }),
                              props.row.logger &&
                              props.row.logger.isOnline == true
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("generals.online")) + " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("generals.offline")) + " "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1211269133
                ),
              }),
              _c("b-table-column", {
                attrs: {
                  field: _vm.tableColumns.visible.field,
                  label: _vm.$t(
                    `component.logger.list.table.${_vm.tableColumns.visible.field}`
                  ),
                  visible:
                    _vm.tableColumns.visible.visible && _vm.showHiddenLoggers,
                  centered: "",
                  width: "5%",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: props.row.visible
                                  ? _vm.$t(
                                      "component.logger.list.table.visible"
                                    )
                                  : _vm.$t(
                                      "component.logger.list.table.hidden"
                                    ),
                                type: "is-light",
                                position: "is-left",
                              },
                            },
                            [
                              _vm.isLoggedFromAdmin
                                ? _c("b-icon", {
                                    staticClass: "clickable",
                                    attrs: {
                                      icon: props.row.visible
                                        ? "eye"
                                        : "eye-slash",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.changeVisibility(props.row)
                                      },
                                    },
                                  })
                                : _c("b-icon", {
                                    attrs: {
                                      icon: props.row.visible
                                        ? "eye"
                                        : "eye-slash",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2018312864
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
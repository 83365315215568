var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.tableData.length > 0
      ? _c(
          "div",
          { staticClass: "last-event-table" },
          _vm._l(_vm.tableData, function (sourceGroup, sourceGroupIndex) {
            return _c(
              "div",
              { key: sourceGroup.group.id },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "groupDashboard",
                        query: { groupId: sourceGroup.group.id },
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                  },
                  [
                    sourceGroup.eventsFeArray.length > 0
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "header header-hover is-flex is-justify-content-space-between mt-4 is-size-5",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  sourceGroup.group.name ||
                                    _vm.$t(
                                      "component.source_group_picker.default_group_name"
                                    )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                sourceGroup.eventsFeArray.length > 0
                  ? _c("div", { staticClass: "subheader" }, [
                      _c("div", { staticClass: "columns is-mobile" }, [
                        _c(
                          "div",
                          { staticClass: "column is-3-desktop is-5-touch" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("component.last_events.table.name")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column is-9-desktop is-7-touch" },
                          [
                            _c("span", { staticClass: "is-hidden-desktop" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.last_events.table.value")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "columns is-hidden-touch" },
                              [
                                _c("div", { staticClass: "column is-4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.last_events.table.value"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "column is-4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.last_events.table.boundaries"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "column is-4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.last_events.table.measured"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "source-event-detail" },
                  _vm._l(sourceGroup.eventsFeArray, function (data, dataIndex) {
                    return _c(
                      "div",
                      { key: data.source.id },
                      [
                        _c(
                          "b-collapse",
                          {
                            staticClass: "is-hidden-desktop",
                            attrs: { animation: "slide" },
                            on: {
                              open: function ($event) {
                                return _vm.groupVisibleCollapseChanged(
                                  sourceGroupIndex,
                                  dataIndex,
                                  true
                                )
                              },
                              close: function ($event) {
                                return _vm.groupVisibleCollapseChanged(
                                  sourceGroupIndex,
                                  dataIndex,
                                  false
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function () {
                                    return [
                                      _c("div", { attrs: { role: "button" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "has-text-weight-medium",
                                          },
                                          [
                                            _c("EventsTableItem", {
                                              attrs: {
                                                data: data,
                                                dateTimeManager:
                                                  _vm.dateTimeManager,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: data.isVisible,
                              callback: function ($$v) {
                                _vm.$set(data, "isVisible", $$v)
                              },
                              expression: "data.isVisible",
                            },
                          },
                          [
                            _c("EventsTableItemMobile", {
                              staticClass: "custom-table-mobile",
                              attrs: {
                                data: data,
                                dateTimeManager: _vm.dateTimeManager,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("EventsTableItem", {
                          staticClass: "is-hidden-touch",
                          attrs: {
                            data: data,
                            dateTimeManager: _vm.dateTimeManager,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            )
          }),
          0
        )
      : _c("div", [
          _vm._v(" " + _vm._s(_vm.$t("component.last_events.no_data")) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
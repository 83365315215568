import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import UserRepository from '@/services/repository/UserRepository';
import AppConfig from '@/configLoader';
var userRepository;
var pdf = require('@/assets/other/loghub_sample_calibration_protocol.pdf');
let CompanyLoggerInfo = class CompanyLoggerInfo extends Vue {
    constructor() {
        super(...arguments);
        this.demo = AppConfig.getConfig().demo.name;
        this.CalibrationStatus = CalibrationStatus;
        this.isLoading = false;
        this.pdf = pdf;
        this.currentUserName = null;
    }
    get hasCalibrationProtocolToDownload() {
        if (this.companyLogger.calibration == null || this.companyLogger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    async created() {
        userRepository = new UserRepository(this);
        this.loadUser();
    }
    async loadUser() {
        let currentUser = await userRepository.getCurrentUser();
        this.currentUserName = currentUser.username;
    }
    get isDemo() {
        return this.currentUserName == this.demo;
    }
    async downloadCalibration() {
        this.isLoading = true;
        await this.$emit('downloadCalibrationProtocol', this.companyLogger.companyLoggerId);
        this.isLoading = false;
    }
    downloadSample() {
        this.$emit('downloadSample', pdf);
    }
};
__decorate([
    Prop({ type: Object })
], CompanyLoggerInfo.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Object })
], CompanyLoggerInfo.prototype, "dateTimeManager", void 0);
CompanyLoggerInfo = __decorate([
    Component
], CompanyLoggerInfo);
export default CompanyLoggerInfo;

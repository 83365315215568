var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "is-flex is-justify-content-space-between mx-0 mb-5" },
        [
          _c("h2", { staticClass: "level-item mb-0 subtitle is-4 mr-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("component.floorPlan.title")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-0-mobile",
                  attrs: { "icon-left": "times-circle" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelEditing()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.cancel_edit")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "columns columns-info-form pb-4",
          class: _vm.isLayoutList ? "is-flex is-flex-direction-column" : "",
          style:
            !_vm.isMobileView && _vm.isUpdating
              ? { height: `${_vm.canvasHeight}px` }
              : "",
        },
        [
          _c(
            "div",
            {
              ref: "floorplanCanvas",
              staticClass: "column",
              class: _vm.isLayoutList ? "is-full" : "is-6",
            },
            [
              _vm.isUploading && _vm.imageFile == null
                ? _c(
                    "image-uploader",
                    {
                      staticClass: "my-2",
                      attrs: {
                        className: [
                          "fileinput",
                          { "fileinput--loaded": _vm.isUploading },
                        ],
                        debug: 0,
                        quality: 1,
                        preview: false,
                        autoRotate: true,
                        accept: ".jpg,.png,.jpeg",
                        maxWidth: _vm.MAX_IMG_DIMENSIONS,
                        maxHeight: _vm.MAX_IMG_DIMENSIONS,
                      },
                      on: { input: _vm.setImage },
                    },
                    [
                      _c(
                        "label",
                        {
                          attrs: { slot: "upload-label", for: "fileInput" },
                          slot: "upload-label",
                        },
                        [
                          _c(
                            "figure",
                            {
                              staticClass:
                                "is-flex is-flex-direction-column border dash is-align-items-center py-5 px-2 is-clickable",
                              attrs: { expanded: "" },
                            },
                            [
                              _c("b-icon", {
                                staticClass:
                                  "control-icon clickable p-0 is-block",
                                attrs: {
                                  type: "is-primary",
                                  icon: "upload",
                                  size: "is-medium",
                                },
                              }),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("component.floorPlan.upload")
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _vm.isUploading && _vm.imageFile != null
                    ? _c("b-button", {
                        staticClass: "canvas-close",
                        attrs: {
                          size: "is-small",
                          type: "is-primary",
                          "icon-right": "times",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.resetData()
                          },
                        },
                      })
                    : _vm._e(),
                  _c("canvas", {
                    attrs: { id: "canvas", height: "1px" },
                    on: {
                      click: function ($event) {
                        return _vm.draw($event)
                      },
                      mousedown: _vm.myDown,
                      mouseup: _vm.myUp,
                      mousemove: _vm.myMove,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "loggerList",
              staticClass: "column",
              class: _vm.isLayoutList ? "is-full" : "is-half",
              style: !_vm.isMobileView ? "overflow-y: auto" : "",
            },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("component.floorPlan.name"),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t("component.floorPlan.name")
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t("component.floorPlan.name")
                    ),
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      placeholder: _vm.$t(
                        "component.floorPlan.namePlaceholder"
                      ),
                      type: "text",
                      name: _vm.$t("component.floorPlan.name"),
                      maxlength: "100",
                    },
                    model: {
                      value: _vm.selectedFloorplanName,
                      callback: function ($$v) {
                        _vm.selectedFloorplanName = $$v
                      },
                      expression: "selectedFloorplanName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "p",
                    { staticClass: "mb-2 has-text-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.floorPlan.sourcesAndDevices")
                          ) +
                          " "
                      ),
                      _vm.editedLocationData.length > 0
                        ? _c(
                            "b-tooltip",
                            {
                              attrs: {
                                multilined: "",
                                size: "is-small",
                                label: _vm.$t(
                                  "component.floorPlan.dragMessage"
                                ),
                                position: "is-right",
                                type: "is-dark",
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "is-clickable",
                                attrs: { icon: "question-circle" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.editedLocationData.length == 0
                    ? _c(
                        "b-message",
                        {
                          staticClass: "mt-2 mb-4",
                          attrs: { type: "is-info", "has-icon": "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("component.floorPlan.message")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.editedLocationData, function (v, index) {
                    return _c(
                      "b-field",
                      {
                        key: index,
                        staticClass: "is-flex is-flex-wrap-wrap",
                        attrs: {
                          label: (index + 1 + ".").toString(),
                          "label-position": "on-border",
                          type: {
                            "is-danger": _vm.errors.has(index + "."),
                          },
                          message: _vm.errors.first(index + "."),
                        },
                      },
                      [
                        _vm.editedLocationData[index].entityId
                          ? _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "is-half-width",
                                attrs: {
                                  expanded: "",
                                  placeholder: _vm.$t(
                                    "component.floorPlan.placeholder"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.selectEntity($event, index)
                                  },
                                },
                                model: {
                                  value: _vm.editedLocationData[index].entityId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editedLocationData[index],
                                      "entityId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editedLocationData[index].entityId",
                                },
                              },
                              [
                                _c(
                                  "optgroup",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "component.floorPlan.sources"
                                      ),
                                    },
                                  },
                                  _vm._l(_vm.sources, function (source) {
                                    return _c(
                                      "option",
                                      {
                                        key: source.id,
                                        domProps: { value: source.id },
                                      },
                                      [_vm._v(" " + _vm._s(source.name) + " ")]
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "optgroup",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "component.floorPlan.devices"
                                      ),
                                    },
                                  },
                                  _vm._l(_vm.devices, function (device) {
                                    return _c(
                                      "option",
                                      {
                                        key: device.deviceId,
                                        domProps: { value: device.deviceId },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(device.deviceName) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _c(
                              "b-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "is-half-width",
                                attrs: {
                                  expanded: "",
                                  placeholder: _vm.$t(
                                    "component.floorPlan.placeholder"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.selectEntity($event, index)
                                  },
                                },
                                model: {
                                  value: _vm.editedLocationData[index].entityId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editedLocationData[index],
                                      "entityId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editedLocationData[index].entityId",
                                },
                              },
                              [
                                _c(
                                  "optgroup",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "component.floorPlan.sources"
                                      ),
                                    },
                                  },
                                  _vm._l(
                                    _vm.getAvailableSources(),
                                    function (source) {
                                      return _c(
                                        "option",
                                        {
                                          key: source.id,
                                          domProps: { value: source.id },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(source.name) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "optgroup",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "component.floorPlan.devices"
                                      ),
                                    },
                                  },
                                  _vm._l(
                                    _vm.getAvailableDevices(),
                                    function (device) {
                                      return _c(
                                        "option",
                                        {
                                          key: device.deviceId,
                                          domProps: { value: device.deviceId },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(device.deviceName) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                        _c("b-button", {
                          staticClass: "ml-2",
                          attrs: { type: "is-primary", "icon-right": "trash" },
                          on: {
                            click: function ($event) {
                              return _vm.removeLogger(index)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  (_vm.numberOfLoggers !== 0 && _vm.isUploading) ||
                  _vm.isUpdating
                    ? _c("b-button", {
                        attrs: {
                          label: _vm.$t("component.floorPlan.resetPlan"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.resetFloorPlan()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.numberOfLoggers !== 0 &&
                  _vm.isUploading &&
                  !_vm.isUpdating
                    ? _c("b-button", {
                        attrs: {
                          type: "is-primary",
                          label: _vm.$t("component.floorPlan.savePlan"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.uploadFloorPlan()
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.isUploading && _vm.isUpdating
                    ? _c("b-button", {
                        attrs: {
                          type: "is-primary",
                          label: _vm.$t("component.floorPlan.updatePlan"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateFloorPlan()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigate },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.cancelEditing,
              discard: _vm.cancelEditing,
              save: function ($event) {
                _vm.isUploading ? _vm.uploadFloorPlan() : _vm.updateFloorPlan()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import LoggerStates from '@/entities/enums/LoggerStates';
import VueUtilities from '@/services/VueUtilities';
import AdminLoggerInfoComponent from '@/views/components/admin/loggers/AdminLoggerInfoComponent.vue';
import CalibrationInfoComponent from '@/views/components/admin/loggers/CalibrationInfoComponent.vue';
import AdminLoggerHistory from '@/views/components/admin/loggers/AdminLoggerHistory.vue';
import AppConfig from '@/configLoader';
import LoggerRepository from '@/services/repository/LoggerRepository';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import LastDataInfoComponent from '@/views/components/admin/loggers/LastDataInfoComponent.vue';
import AdminLoggerChart from '@/views/components/admin/loggers/AdminLoggerChart.vue';
import UserPreferences from '@/services/UserPreferences';
import PublishLoggerModal from './PublishLoggerModal.vue';
import ReadDataFromLoggerModal from './ReadDataFromLoggerModal.vue';
import SelectCompanyModal from './SelectCompanyModal.vue';
import LocalStorageKeys from '@/entities/enums/LocalStorageKeys';
import CreateVerificationKeyModal from './CreateVerificationKeyModal.vue';
import { ChartComponents } from '@/entities/enums/ChartComponents';
import LoggerActionsDropdown from '@/views/components/admin/loggers/LoggerActionsDropdown.vue';
import SourceRepository from '@/services/repository/SourceRepository';
var sourceRepository;
var loggerRepository;
var companyLoggerRepository;
let AdminLoggerDetail = class AdminLoggerDetail extends Vue {
    constructor() {
        super(...arguments);
        this.isSerialNumberModalActive = false;
        this.isCompanyModalActive = false;
        this.isReadDataModalActive = false;
        this.isVerificationKeyModalActive = false;
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.AdminLoggerDetailLayoutList);
        this.activeTab = UserPreferences.LocalStorageKeys.AdminLoggerDetailLayoutActiveTab.defaultValue;
        this.prevRoute = null;
        this.chartEntityAdmin = null;
    }
    get canPublish() {
        return this.logger.state == LoggerStates.AVAILABLE;
    }
    get canUnpublish() {
        return this.logger.state == LoggerStates.DEACTIVATED;
    }
    get canActivate() {
        return this.logger.state == LoggerStates.PUBLISHED;
    }
    get canDeactivate() {
        return this.logger.state == LoggerStates.ACTIVATED;
    }
    get canReturn() {
        return this.logger.state == LoggerStates.ACTIVATED;
    }
    get canRevert() {
        return this.logger.state == LoggerStates.ACTIVATED && this.logger.currentlyPublishedAs != null;
    }
    get canReadData() {
        return (this.logger?.currentlyPublishedAs?.isOnline === true && AppConfig.getConfig().features.read_logger_data === true);
    }
    get loggerBreadcrumb() {
        return this.logger?.currentlyPublishedAs?.serialNumber
            ? this.logger?.currentlyPublishedAs?.serialNumber
            : this.logger.macAddress;
    }
    get loggerCompanyBreadcrumb() {
        return this.logger?.currentlyPublishedAs?.company?.companyName
            ? this.logger?.currentlyPublishedAs?.company?.companyName
            : this.logger.macAddress;
    }
    created() {
        loggerRepository = new LoggerRepository(this);
        sourceRepository = new SourceRepository(this);
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.prevRoute = JSON.parse(localStorage.getItem(LocalStorageKeys.ADMIN_PREV_ROUTE));
        if (!this.isLayoutList) {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.AdminLoggerDetailLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
    }
    closeModal() {
        this.isSerialNumberModalActive = false;
        this.isCompanyModalActive = false;
        this.isReadDataModalActive = false;
        this.isVerificationKeyModalActive = false;
    }
    readData(device) {
        this.isReadDataModalActive = false;
        this.$emit('readData', device);
    }
    addVerificationKey() {
        this.isVerificationKeyModalActive = true;
    }
    createVerificationKey(verificationKey) {
        this.isVerificationKeyModalActive = false;
        this.$emit('createVerificationKey', verificationKey);
    }
    deleteVerificationKey() {
        this.$emit('deleteVerificationKey');
    }
    publishClicked() {
        this.isSerialNumberModalActive = true;
    }
    activateClicked() {
        this.isCompanyModalActive = true;
    }
    returnFromDepartmentClicked() {
        this.$emit('returnFromDepartment');
    }
    changedActiveTab(event) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerDetailLayoutActiveTab, event);
        if (event == 0) {
            this.$emit('selectedFromDateChange', this.selectedFromDate);
        }
        this.$router
            .replace({
            query: Object.assign({}, this.$route.query, {
                activeTab: this.activeTab
            })
        })
            .catch((err) => err);
    }
    async publishLogger(serialNumber, measuredEventTypes) {
        this.isSerialNumberModalActive = false;
        if (this.logger.state == LoggerStates.AVAILABLE) {
            let result = await loggerRepository.adminPublishLogger(this.logger.macAddress, serialNumber, measuredEventTypes);
            if (result) {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.messages.publishSuccess').toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.messages.publishFailed').toString());
            }
        }
        this.reloadLogger();
    }
    async unpublishLogger() {
        if ((this.logger.state == LoggerStates.PUBLISHED || this.logger.state == LoggerStates.DEACTIVATED) &&
            this.logger.currentlyPublishedAs) {
            let result = await companyLoggerRepository.adminUnpublishLogger(this.logger.currentlyPublishedAs.companyLoggerId);
            if (result) {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.messages.unpublishSuccess').toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.messages.unpublishFailed').toString());
            }
            this.reloadLogger();
        }
    }
    async activateLogger(company, logger, sourceCreationEnabled) {
        this.isCompanyModalActive = false;
        if (this.logger.state == LoggerStates.PUBLISHED && this.logger.currentlyPublishedAs) {
            let result = await companyLoggerRepository.adminActivateLogger(this.logger.currentlyPublishedAs.companyLoggerId, company.companyId.toString());
            if (result) {
                if (sourceCreationEnabled) {
                    this.createSource(company, logger);
                }
                else {
                    this.loadAfterSuccess();
                }
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.messages.activateFailed').toString());
            }
        }
    }
    async createSource(company, logger) {
        let sourceDto = {
            Name: logger.additionalProperties.name
        };
        sourceDto.LoggerId = logger.currentlyPublishedAs.companyLoggerId;
        let result = await sourceRepository.createSource(sourceDto, company.companyId);
        if (result) {
            this.loadAfterSuccess();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.messages.activateFailed').toString());
        }
    }
    loadAfterSuccess() {
        VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.messages.activateSuccess').toString());
        this.reloadLogger();
    }
    eventHandler(hasDataForTimerange, chartEntity, chartComponent) {
        if (hasDataForTimerange) {
            if (chartComponent == ChartComponents.ADMINLOGGERCHART) {
                this.chartEntityAdmin = chartEntity;
                window.addEventListener('resize', this.resizeHandlerAdmin);
            }
        }
        else {
            if (chartComponent == ChartComponents.ADMINLOGGERCHART) {
                this.chartEntityAdmin = chartEntity;
                window.removeEventListener('resize', this.resizeHandlerAdmin);
            }
        }
    }
    //Seperate function for remove 'resize' event listener
    resizeHandlerAdmin() {
        if (this.chartEntityAdmin)
            this.chartEntityAdmin.makeResponsive();
    }
    selectedFromDateChange(date) {
        this.$emit('selectedFromDateChange', date);
    }
    async deactivateLogger() {
        if (this.logger.state == LoggerStates.ACTIVATED && this.logger.currentlyPublishedAs) {
            let result = await companyLoggerRepository.adminDeactivateLogger(this.logger.currentlyPublishedAs.companyLoggerId);
            if (result) {
                VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.messages.deactivateSuccess').toString());
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.messages.deactivateFailed').toString());
            }
            this.reloadLogger();
        }
    }
    async saveLoggerEdit(note) {
        let result = await loggerRepository.adminEditLogger(this.logger.id, note);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.messages.editSucess').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.messages.editFailure').toString());
        }
        this.reloadLogger();
    }
    async typesSelected(selectedTypes) {
        let result = await companyLoggerRepository.adminChangeAllowedTypesForLogger(this.companyLogger.companyLoggerId, selectedTypes);
        if (result) {
            VueUtilities.openSuccessToast(this, this.$t('admin.component.loggers.detail.messages.editSucess').toString());
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.detail.messages.editFailure').toString());
        }
        this.reloadLogger();
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        this.$emit('selectedFromDateChange', this.selectedFromDate);
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.AdminLoggerDetailLayoutList, this.isLayoutList);
        if (!this.isLayoutList) {
            this.activeTab = parseInt(this.$route.query.activeTab)
                ? parseInt(this.$route.query.activeTab)
                : UserPreferences.LocalStorageKeys.AdminLoggerDetailLayoutActiveTab.defaultValue;
            this.changedActiveTab(this.activeTab);
        }
    }
    reloadLogger() {
        this.$emit('reloadLogger');
    }
    openReadDataModal() {
        this.isReadDataModalActive = true;
    }
    revertDevice() {
        this.$emit('revertDevice');
    }
};
__decorate([
    Prop({ type: Object })
], AdminLoggerDetail.prototype, "logger", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerDetail.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerDetail.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean })
], AdminLoggerDetail.prototype, "isAdminLoggerChartLoading", void 0);
__decorate([
    Prop({ type: Object })
], AdminLoggerDetail.prototype, "chartData", void 0);
__decorate([
    Prop({ type: Date })
], AdminLoggerDetail.prototype, "selectedFromDate", void 0);
AdminLoggerDetail = __decorate([
    Component({
        components: {
            LastDataInfoComponent,
            AdminLoggerInfoComponent,
            CalibrationInfoComponent,
            AdminLoggerHistory,
            AdminLoggerChart,
            PublishLoggerModal,
            SelectCompanyModal,
            ReadDataFromLoggerModal,
            CreateVerificationKeyModal,
            LoggerActionsDropdown
        }
    })
], AdminLoggerDetail);
export default AdminLoggerDetail;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("CompanyLoggerConfigurationList", {
        staticClass: "box",
        attrs: {
          deviceId: _vm.deviceId,
          companyLoggerList: _vm.companyLoggerList,
          dateTimeManager: _vm.dateTimeManager,
        },
        on: { "reload-loggers": _vm.reloadData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
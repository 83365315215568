import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import CompanyLoggerInfo from '../components/logger/CompanyLoggerInfo.vue';
import { CompanyLogger } from '@/entities/models/CompanyLogger';
import FileDownloader from '@/services/FileDownloader';
var companyLoggerRepository;
let CompanyLoggerDetail = class CompanyLoggerDetail extends Vue {
    constructor() {
        super(...arguments);
        this.companyLogger = new CompanyLogger();
        this.dateTimeManager = null;
        this.routeToNavigate = null;
        this.isLoading = true;
    }
    async created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        await this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(companyLoggerRepository.getCompanyLogger(this.$route.params.locationId));
        await Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0]);
        });
    }
    async processLoadedDataForPage(logger) {
        this.companyLogger = logger;
        this.isLoading = false;
    }
    async downloadSample(pdf) {
        FileDownloader.downloadSampleFile(pdf.default, 'Sample.pdf');
    }
    async downloadCalibration() {
        await companyLoggerRepository.downloadCalibrationProtocol(this.companyLogger.companyLoggerId);
    }
};
CompanyLoggerDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.SOURCE_GROUP) && vm.$ability.can(Actions.READ, Subjects.LOGGER)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: { CompanyLoggerInfo }
    })
], CompanyLoggerDetail);
export default CompanyLoggerDetail;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "multi-header has-bottom-margin mx-4-mobile" }, [
        _c("div", { staticClass: "is-flex is-align-self-center" }, [
          _c(
            "nav",
            {
              staticClass: "breadcrumb",
              attrs: { "aria-label": "breadcrumb" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _vm._m(0),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                      ]
                    ),
                  ]),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "dashboard",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "home" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generals.home")))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "settings",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("component.settings.title")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "configuration_company_loggers",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.settings.location.company_logger_settings"
                                )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(_vm._s(_vm.companyLogger.serialNumber)),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "tile is-child box" },
                [
                  _c("CompanyLoggerInfo", {
                    ref: "companyLoggerInfoComponent",
                    attrs: {
                      companyLogger: _vm.companyLogger,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                    on: {
                      downloadSample: _vm.downloadSample,
                      downloadCalibration: _vm.downloadCalibration,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "h1",
        { staticClass: "title is-3 is-spaced has-text-centered-touch" },
        [_vm._v(" " + _vm._s(_vm.$t("company_loggers.title")) + " ")]
      ),
      _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.LOGGER)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("CompanyLoggerConfigurationList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    companyLoggerList: _vm.companyLoggerList,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { "reload-loggers": _vm.createApiCalls },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.isLoading &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.LOGGER)
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("IdleLoggersList", {
                  staticClass: "box tile is-child",
                  attrs: {
                    companyLoggerList: _vm.companyLoggerList,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { "reload-loggers": _vm.loadData },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "multi-header has-bottom-margin mx-4-mobile" }, [
        _c("div", { staticClass: "is-flex is-align-self-center" }, [
          _c(
            "nav",
            {
              staticClass: "breadcrumb",
              attrs: { "aria-label": "breadcrumb" },
            },
            [
              _c("h1", { staticClass: "title has-text-centered-touch" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _vm._m(0),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generals.back")))]),
                      ]
                    ),
                  ]),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "dashboard",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "home" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generals.home")))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "settings",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("component.settings.title")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "configuration_loggers",
                              params: {
                                lang: _vm.$route.params.lang,
                                departmentId: _vm.$route.params.departmentId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.settings.location.logger_settings"
                                )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("li", { staticClass: "is-active" }, [
                    _c("a", { attrs: { href: "", "aria-current": "page" } }, [
                      _vm._v(_vm._s(_vm.source.name)),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE) &&
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.LOGGER)
          ? _c(
              "div",
              { staticClass: "is-justify-content-flex-end" },
              [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "top-layer-tooltips",
                    attrs: {
                      position: "is-bottom-left",
                      "append-to-body": "",
                      "aria-role": "list",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.devices.detail.actions.title"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    { staticStyle: { "font-size": "1rem" } },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "ellipsis-v",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2812016860
                    ),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteLogger()
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "trash" },
                        }),
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.logger.list.delete_logger")
                            )
                          ),
                        ]),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              type: "is-dark",
                              label: _vm.$t(
                                "component.logger.list.delete_logger_note"
                              ),
                              position: "is-left",
                              multilined: "",
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                size: "is-small",
                                icon: "question-circle",
                                type: "is-black",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.$ability.can(
                      _vm.$permActions.MANAGE,
                      _vm.$permSubjects.SOURCE
                    ) &&
                    _vm.$ability.can(
                      _vm.$permActions.READ,
                      _vm.$permSubjects.LOGGER
                    )
                      ? _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.changeLogger(
                                  _vm.source.logger ? true : false
                                )
                              },
                            },
                          },
                          [
                            _vm.source.logger
                              ? [
                                  _c("b-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "fas fa-exchange-alt" },
                                  }),
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "component.logger.list.reassign_loggers"
                                          )
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "component.logger.list.reassign_loggers_note"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-black",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c("b-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "plus-square" },
                                  }),
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "component.logger.list.assign_loggers"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "component.logger.list.assign_loggers_note"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-black",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: {
                          disabled:
                            (!_vm.source.logger &&
                              _vm.$ability.can(
                                _vm.$permActions.MANAGE,
                                _vm.$permSubjects.SOURCE
                              )) ||
                            _vm.isEditing,
                          "aria-role": "listitem",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.discardLogger()
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "unlink" },
                        }),
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.logger.list.discard_logger")
                            )
                          ),
                        ]),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              type: "is-dark",
                              label: _vm.$t(
                                "component.logger.list.discard_logger_note"
                              ),
                              position: "is-left",
                              multilined: "",
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                size: "is-small",
                                icon: "question-circle",
                                type: "is-black",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.isLoading
                      ? _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              disabled:
                                (this.source.activeService &&
                                  !this.source.activeService.endTime) ||
                                (this.source.activeService &&
                                  this.source.activeService.endTime &&
                                  this.source.activeService.endTime * 1000 >
                                    new Date().getTime()),
                              "aria-role": "listitem",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openServiceModal()
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-1",
                              attrs: { icon: "wrench" },
                            }),
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("component.logger.service.btn_label")
                                )
                              ),
                            ]),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  type: "is-dark",
                                  label: _vm.$t(
                                    "component.logger.service.btn_tooltip"
                                  ),
                                  position: "is-left",
                                  multilined: "",
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    size: "is-small",
                                    icon: "question-circle",
                                    type: "is-black",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function ($event) {
                            return _vm.changePausedMeasurementFlag()
                          },
                        },
                      },
                      [
                        _vm.source.pausedMeasurement
                          ? [
                              _c("b-icon", {
                                staticClass: "mr-1",
                                attrs: { icon: "fas fa-play-circle" },
                              }),
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("loggers.start_note"))
                                ),
                              ]),
                            ]
                          : [
                              _c("b-icon", {
                                staticClass: "mr-1",
                                attrs: { icon: "fas fa-pause-circle" },
                              }),
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.$t("loggers.pause_note"))),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c("div", { staticClass: "tile is-ancestor is-vertical" }, [
            _c("div", { staticClass: "tile is-parent" }, [
              _c(
                "div",
                { staticClass: "tile is-child box" },
                [
                  _c("LoggerInfo", {
                    ref: "loggerInfoComponent",
                    attrs: {
                      source: _vm.source,
                      sourceGroups: _vm.sourceGroups,
                      quantities: _vm.quantities,
                      eventTypes: _vm.eventTypes,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                    on: {
                      editingLogger: _vm.editingLogger,
                      reloadSource: _vm.loadData,
                      editService: _vm.openEditServiceModal,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c("LoggerChanges", {
                  staticClass: "tile is-child box",
                  attrs: {
                    sourceChanges: _vm.sourceChanges,
                    dateTimeManager: _vm.dateTimeManager,
                  },
                  on: { reloadSource: _vm.loadData },
                }),
              ],
              1
            ),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isUnsavedChangesModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeUnsavedChangesModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isUnsavedChangesModalActive = $event
            },
            "close-modal": _vm.closeUnsavedChangesModal,
          },
        },
        [
          _c("UnsavedChangesDialog", {
            attrs: { routeToNavigate: _vm.routeToNavigate },
            on: {
              modalClosed: _vm.closeUnsavedChangesModal,
              cancel: _vm.closeUnsavedChangesModal,
              save: _vm.saveUnsavedChanges,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isServiceModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeServiceModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isServiceModalActive = $event
            },
            "close-modal": _vm.closeServiceModal,
          },
        },
        [
          _c("SetServiceModal", {
            attrs: { source: _vm.source, dateTimeManager: _vm.dateTimeManager },
            on: {
              modalClosed: _vm.closeServiceModal,
              cancel: _vm.closeServiceModal,
              createService: _vm.createService,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isEditServiceModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeEditServiceModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isEditServiceModalActive = $event
            },
            "close-modal": _vm.closeEditServiceModal,
          },
        },
        [
          _c("EditServiceModal", {
            attrs: { source: _vm.source, dateTimeManager: _vm.dateTimeManager },
            on: {
              modalClosed: _vm.closeEditServiceModal,
              cancel: _vm.closeEditServiceModal,
              editService: _vm.editService,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon mr-0" }, [
      _c("i", { staticClass: "fas fa-chevron-left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("company_loggers.msg.file_download_info")) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("h2", { staticClass: "subtitle is-4 mb-5" }, [
        _vm._v(_vm._s(_vm.$t("company_loggers.detail.company_logger_detail"))),
      ]),
      _c("div", { staticClass: "columns columns-info-form" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.serialNumber"),
                },
              },
              [
                _c(
                  "p",
                  { staticClass: "is-flex is-align-items-center" },
                  [
                    _vm._v(" " + _vm._s(_vm.companyLogger.serialNumber) + " "),
                    !_vm.companyLogger.active
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t(
                                "component.logger.overview.deactivated_logger"
                              ),
                              position: "is-right",
                              type: "is-dark",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "ml-1",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                icon: "exclamation-triangle",
                                type: "is-danger",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.macAddress"),
                },
              },
              [
                _c("p", { staticClass: "is-flex is-align-items-center" }, [
                  _vm._v(" " + _vm._s(_vm.companyLogger.macAddress) + " "),
                ]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.connectionStatus"),
                },
              },
              [
                _c(
                  "p",
                  [
                    _c("b-icon", {
                      staticClass: "is-size-7",
                      attrs: {
                        type:
                          _vm.companyLogger.isOnline == true
                            ? "is-success"
                            : "is-danger",
                        icon: "circle",
                      },
                    }),
                    _vm.companyLogger.isOnline == true
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("generals.online")))])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$t("generals.offline"))),
                        ]),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.battery"),
                },
              },
              [
                _vm.companyLogger.battery != null
                  ? _c(
                      "p",
                      [
                        _vm._v(" " + _vm._s(_vm.companyLogger.battery) + "% "),
                        _vm.companyLogger.battery <= 15
                          ? _c("b-icon", {
                              staticClass: "is-size-5",
                              attrs: {
                                icon: "exclamation-triangle",
                                type: "is-primary",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.availableTypes"),
                },
              },
              [
                _vm.companyLogger.availableTypes.length > 0
                  ? _c(
                      "p",
                      _vm._l(
                        _vm.companyLogger.availableTypes,
                        function (option, index) {
                          return _c("span", { key: option.id }, [
                            _vm._v(_vm._s(option.name)),
                            index != _vm.companyLogger.availableTypes.length - 1
                              ? _c("span", [_vm._v(", ")])
                              : _vm._e(),
                          ])
                        }
                      ),
                      0
                    )
                  : _c("p", [_vm._v("-")]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.activatedAt"),
                },
              },
              [
                _c("p", { staticClass: "is-flex is-align-items-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(_vm.companyLogger.activatedAt * 1000),
                          "UTC",
                          null,
                          ""
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.calibration"),
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "is-flex is-align-items-center" },
                  [
                    _vm.companyLogger.calibration != null
                      ? _c(
                          "b-tag",
                          {
                            attrs: {
                              rounded: "",
                              type: _vm.companyLogger.calibration.status.type,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `calibration_status.${_vm.companyLogger.calibration.status.name}`
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "b-tag",
                          {
                            attrs: {
                              type: _vm.CalibrationStatus.UNKNOWN.type,
                              rounded: "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `calibration_status.${_vm.CalibrationStatus.UNKNOWN.name}`
                                )
                              )
                            ),
                          ]
                        ),
                    _vm.$ability.can(
                      _vm.$permActions.READ,
                      _vm.$permSubjects.LOGGER
                    ) &&
                    _vm.hasCalibrationProtocolToDownload &&
                    !_vm.isDemo
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              size: "is-small",
                              type: "is-primary",
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadCalibration()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.location.detail.download")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.$ability.can(
                      _vm.$permActions.READ,
                      _vm.$permSubjects.LOGGER
                    ) &&
                    _vm.hasCalibrationProtocolToDownload &&
                    _vm.isDemo
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              size: "is-small",
                              type: "is-primary",
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadSample()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.location.detail.download")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.calibratedAt"),
                },
              },
              [
                _vm.companyLogger.calibration != null
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(
                                _vm.companyLogger.calibration.calibratedAt *
                                  1000
                              ),
                              "UTC",
                              null,
                              ""
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.expiresAt"),
                },
              },
              [
                _vm.companyLogger.calibration != null
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(
                                _vm.companyLogger.calibration.expiresAt * 1000
                              ),
                              "UTC",
                              null,
                              ""
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("company_loggers.properties.lastSeen"),
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.companyLogger.lastSeen * 1000)
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Source } from '@/entities/models/Source';
import { SourceGroup } from '@/entities/models/SourceGroup';
import LoggerInfo from '@/views/components/logger/LoggerInfo.vue';
import LoggerChanges from '@/views/components/logger/LoggerChanges.vue';
import SetServiceModal from '@/views/components/logger/SetServiceModal.vue';
import EditServiceModal from '@/views/components/logger/EditServiceModal.vue';
import VueUtilities from '@/services/VueUtilities';
import SourceRepository from '@/services/repository/SourceRepository';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import EventType from '@/entities/EventType';
import EventDomain from '@/entities/enums/eventDomains';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import UnsavedChangesDialog from '@/views/components/common/UnsavedChangesDialog.vue';
import { SourceChanges } from '@/entities/models/SourceChanges';
import { SourceService } from '@/entities/models/SourceService';
var sourceRepository;
var sourceGroupRepository;
var eventTypeRepository;
let LoggerDetail = class LoggerDetail extends Vue {
    constructor() {
        super(...arguments);
        this.source = new Source();
        this.quantities = [];
        this.sourceGroups = new Array();
        this.eventTypes = [];
        this.dateTimeManager = null;
        this.sourceChanges = [];
        this.isUnsavedChangesModalActive = false;
        this.isServiceModalActive = false;
        this.isEditServiceModalActive = false;
        this.routeToNavigate = null;
        this.isLoading = true;
        this.isEditing = false;
        this.currentTime = new Date();
    }
    get hasService() {
        return (this.source.activeService && !this.source.activeService.endTime) ||
            (this.source.activeService &&
                this.source.activeService.endTime &&
                this.source.activeService.endTime * 1000 > new Date().getTime())
            ? true
            : false;
    }
    async created() {
        sourceRepository = new SourceRepository(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        eventTypeRepository = EventTypeRepository.getInstance(this);
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        await this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        let allPromises = [];
        allPromises.push(sourceGroupRepository.getSourceGroups(null, null, true));
        allPromises.push(sourceRepository.getSource(this.$route.params.locationId));
        allPromises.push(eventTypeRepository.getTypesFilteredByDomain(EventDomain.MEASURED, true));
        allPromises.push(eventTypeRepository.getSelectedUnits());
        allPromises.push(sourceRepository.getLoggerChanges(this.$route.params.locationId));
        await Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3], response[4]);
        });
    }
    async processLoadedDataForPage(sourceGroups, source, eventTypes, units, sourceChanges) {
        this.source = source;
        this.sourceGroups = sourceGroups.getData();
        this.sourceGroups.push(SourceGroup.createNullSourceGroup([]));
        this.eventTypes = EventType.addUnits(eventTypes, units);
        this.sourceChanges = sourceChanges;
        if (this.source.logger) {
            this.quantities = await sourceRepository.getQuantities(source.id);
        }
        SourceChanges.sortByDate(this.sourceChanges);
        this.isLoading = false;
    }
    editingLogger() {
        this.isEditing = !this.isEditing;
    }
    changeLogger(reassigning = false) {
        this.$refs.loggerInfoComponent.changeLogger(reassigning);
    }
    hasChanges() {
        return this.$refs.loggerInfoComponent.hasChanges;
    }
    discardLogger() {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.logger.change_modal.confirmDiscardDialog.title')} (${this.source?.logger?.serialNumber})`,
            message: this.$t('component.logger.list.discard_logger_note'),
            confirmText: `${this.$t('component.logger.change_modal.confirmDiscardDialog.confirm')}`,
            cancelText: `${this.$t('component.logger.change_modal.confirmDiscardDialog.deny')}`,
            type: 'is-primary',
            hasIcon: true,
            onConfirm: async () => {
                let result = await sourceRepository.unassignLogger(this.source.id);
                if (result.logger == null) {
                    VueUtilities.openSuccessToast(this, this.$t('component.logger.change_modal.confirmDiscardDialog.success'));
                    this.loadData();
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.logger.change_modal.confirmDiscardDialog.failure'));
                }
            },
            onCancel: () => { }
        });
    }
    deleteLogger() {
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.logger.list.delete_logger')}`,
            message: this.$t('component.logger.list.delete_logger_note'),
            confirmText: `${this.$t('component.logger.remove_modal.confirm')}`,
            cancelText: `${this.$t('component.logger.remove_modal.deny')}`,
            type: 'is-primary',
            hasIcon: true,
            onConfirm: async () => {
                let result = await sourceRepository.deleteLogger(this.source.id);
                if (result) {
                    VueUtilities.openSuccessToast(this, this.$t('component.logger.remove_modal.success'));
                    this.$router
                        .push({
                        name: 'configuration_loggers',
                        params: {
                            lang: this.$route.params.lang,
                            departmentId: this.$route.params.departmentId
                        }
                    })
                        .catch((err) => err);
                }
                else {
                    VueUtilities.openErrorToast(this, this.$t('component.logger.remove_modal.failure'));
                }
            },
            onCancel: () => { }
        });
    }
    async createService(service) {
        this.isLoading = true;
        let result = await sourceRepository.setServiceForSource(this.source.id, service);
        if (result instanceof SourceService) {
            VueUtilities.openSuccessToast(this, this.$t('component.logger.service.modal.success'));
            this.source.setService(result);
            this.closeServiceModal();
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.service.modal.failure'));
        }
        this.isLoading = false;
    }
    async editService(serviceDto) {
        this.isLoading = true;
        let result = await sourceRepository.updateServiceForSource(this.source.id, this.source.activeService.id, serviceDto);
        if (result instanceof SourceService) {
            VueUtilities.openSuccessToast(this, this.$t('component.logger.service.edit_modal.success'));
            this.closeEditServiceModal();
            this.$nextTick(() => {
                this.checkIfServiceIsStillValid(result);
            });
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logger.service.edit_modal.failure'));
        }
        this.isLoading = false;
    }
    /**
     * Check if service time is not less than current tomi (eg. window opened for long time).
     * If service is ended, reload source and data.
     */
    checkIfServiceIsStillValid(service) {
        if (service.endTime * 1000 < new Date().getTime()) {
            this.loadData();
        }
        else {
            this.source.setService(service);
        }
    }
    async changePausedMeasurementFlag() {
        let sourceDTO = {
            Name: this.source.name,
            Description: this.source.description,
            Visible: this.source.visible,
            PausedMeasurement: !this.source.pausedMeasurement
        };
        let updateResult = await sourceRepository.updateSource(this.source.id, sourceDTO);
        if (updateResult) {
            VueUtilities.openSuccessToast(this, this.$t('component.location.detail.msg.edit_success').toString());
            this.loadData();
        }
    }
    closeUnsavedChangesModal() {
        this.isUnsavedChangesModalActive = false;
    }
    saveUnsavedChanges() {
        this.closeUnsavedChangesModal();
        this.$refs.loggerInfoComponent.validate();
    }
    openServiceModal() {
        this.isServiceModalActive = true;
    }
    closeServiceModal() {
        this.isServiceModalActive = false;
    }
    closeEditServiceModal() {
        this.isEditServiceModalActive = false;
    }
    openEditServiceModal() {
        this.checkIfServiceIsStillValid(this.source.activeService);
        this.isEditServiceModalActive = true;
    }
};
LoggerDetail = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.SOURCE_GROUP) && vm.$ability.can(Actions.READ, Subjects.SOURCE)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteLeave(to, from, next) {
            if (this.hasChanges() && to.query.confirmed != 'true') {
                this.routeToNavigate = to;
                this.isUnsavedChangesModalActive = true;
            }
            else {
                next();
            }
        },
        components: { LoggerInfo, UnsavedChangesDialog, LoggerChanges, SetServiceModal, EditServiceModal }
    })
], LoggerDetail);
export default LoggerDetail;

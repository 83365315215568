import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
var companyLoggerRepository;
let IdleLoggersList = class IdleLoggersList extends Vue {
    get deactivatedLoggers() {
        return this.companyLoggerList.filter((x) => !x.active);
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
    async downloadCalibration(id) {
        await companyLoggerRepository.downloadCalibrationProtocol(id);
    }
};
__decorate([
    Prop({ type: Array })
], IdleLoggersList.prototype, "companyLoggerList", void 0);
__decorate([
    Prop({ type: Object })
], IdleLoggersList.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], IdleLoggersList.prototype, "inWizard", void 0);
IdleLoggersList = __decorate([
    Component
], IdleLoggersList);
export default IdleLoggersList;

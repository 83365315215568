var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-header-level" }, [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("company_loggers.deactivated_loggers.title")) +
              " "
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          staticClass: "idle-loggers-table",
          attrs: { data: _vm.deactivatedLoggers },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content has-text-grey has-text-centered",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("company_loggers.list.empty")) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table-column", {
            attrs: { label: _vm.$t("company_loggers.properties.serialNumber") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_c("p", [_vm._v(_vm._s(props.row.serialNumber))])]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { label: _vm.$t("company_loggers.properties.macAddress") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_c("p", [_vm._v(_vm._s(props.row.macAddress))])]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { label: _vm.$t("company_loggers.properties.calibration") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-align-items-center ml-3-mobile",
                      },
                      [
                        props.row.calibration
                          ? _c(
                              "b-tag",
                              {
                                attrs: {
                                  rounded: "",
                                  type: props.row.calibration.status.type,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `calibration_status.${props.row.calibration.status.name}`
                                    )
                                  ) + " "
                                ),
                              ]
                            )
                          : _c(
                              "b-tag",
                              {
                                attrs: {
                                  type: _vm.CalibrationStatus.UNKNOWN.type,
                                  rounded: "",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      `calibration_status.${_vm.CalibrationStatus.UNKNOWN.name}`
                                    )
                                  )
                                ),
                              ]
                            ),
                        _vm.$ability.can(
                          _vm.$permActions.READ,
                          _vm.$permSubjects.LOGGER
                        ) && _vm.hasCalibrationProtocolToDownload(props.row)
                          ? [
                              _c(
                                "b-tooltip",
                                {
                                  staticClass: "is-hidden-mobile",
                                  attrs: {
                                    label: _vm.$t("generals.download"),
                                    position: "is-left",
                                    type: "is-light",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "control-icon clickable",
                                    attrs: { icon: "file-download" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.downloadCalibration(
                                          props.row.companyLoggerId
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "is-hidden-tablet" },
                                [
                                  _c("b-icon", {
                                    staticClass: "control-icon clickable",
                                    attrs: { icon: "file-download" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.downloadCalibration(
                                          props.row.companyLoggerId
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: { label: _vm.$t("company_loggers.properties.expiresAt") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    props.row.calibration
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dateTimeManager.formatTime(
                                  new Date(
                                    props.row.calibration.calibratedAt * 1000
                                  ),
                                  null,
                                  null,
                                  null
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "column is-flex is-align-items-center" },
    [
      _c("b-icon", {
        staticClass: "type-icon is-justify-content-flex-start",
        attrs: { icon: _vm.event.eventType.icon },
      }),
      _vm.boundary
        ? _c("div", { staticClass: "boundaries" }, [
            _c("div", { staticClass: "is-size-7" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.DecimalConversion.toCommaString(
                      _vm.boundary.lowerAlarmBoundary.Value
                    )
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "is-size-7 is-hidden-pipe" }, [
              _vm._v("|"),
            ]),
            _c("div", { staticClass: "is-size-7" }, [
              _vm.boundary.lowerWarningBoundary.Value != null
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.DecimalConversion.toCommaString(
                            _vm.boundary.lowerWarningBoundary.Value
                          )
                        )
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
            _c("div", { staticClass: "is-size-7 is-hidden-pipe" }, [
              _vm._v("|"),
            ]),
            _c("div", { staticClass: "is-size-7" }, [
              _vm.boundary.upperWarningBoundary.Value != null
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.DecimalConversion.toCommaString(
                            _vm.boundary.upperWarningBoundary.Value
                          )
                        )
                    ),
                  ])
                : _c("span", [_vm._v("-")]),
            ]),
            _c("div", { staticClass: "is-size-7 is-hidden-pipe" }, [
              _vm._v("|"),
            ]),
            _c("div", { staticClass: "is-size-7" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.DecimalConversion.toCommaString(
                      _vm.boundary.upperAlarmBoundary.Value
                    )
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "is-size-7" }, [
              _c("span", [
                _vm._v(
                  "(" + _vm._s(_vm.boundary.upperAlarmBoundary.Unit) + ")"
                ),
              ]),
            ]),
          ])
        : _c("div", [
            _c("span", { staticClass: "is-italic is-grey is-size-7" }, [
              _vm._v(_vm._s(_vm.$t(`measurement_status.NotAvailable`))),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }